import React from 'react'
import styled from 'styled-components'
import variables from '../../styles/variables'

const Svg = styled.svg`
  
  background-color:#0000 ;
`
export function NounCash() {
  return (
    
    <Svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62.4194 52.3008C57.5202 44.5704 59.9267 36.9768 62.4738 28.9368C64.9786 21.032 67.5682 12.8584 62.4194 4.7328L62.2723 4.5H35.9643L36.4523 5.2704C41.3515 13.0008 38.9451 20.5944 36.3979 28.6344C35.5739 31.2336 34.7259 33.9192 34.1619 36.5768C34.2171 36.5552 34.2715 36.532 34.3267 36.5104C34.5859 36.4104 34.8755 36.54 34.9747 36.7984C35.0739 37.0568 34.9451 37.3472 34.6867 37.4464C34.4307 37.5448 34.1795 37.6488 33.9299 37.7552C33.2275 41.6864 33.3371 44.992 34.2643 48.084C34.5435 47.8152 34.8139 47.5464 35.0771 47.2728C34.2731 44.2488 34.2795 40.9712 35.0923 37.0208C35.6451 34.3352 36.5139 31.5912 37.3547 28.9368C39.7803 21.2816 42.2851 13.3744 37.7651 5.5032H61.7178C66.4226 13.1528 64.0394 20.6752 61.5178 28.6336C59.0923 36.2888 56.5875 44.196 61.1074 52.0672H44.7786C44.6362 52.3928 44.5011 52.7272 44.3723 53.0704H49.1298C49.2722 52.8448 49.3514 52.7088 49.3538 52.7056C49.493 52.4664 49.7994 52.384 50.0394 52.5232C50.2354 52.6368 50.3235 52.8616 50.2747 53.0712H62.9082L62.4194 52.3008Z" fill="white"/>
    <path d="M58.5547 29.472C58.7275 27.5352 57.9371 25.6384 56.3299 24.1288C54.7563 22.6512 52.5779 21.7208 50.1979 21.5088C47.8179 21.296 45.5099 21.828 43.6995 23.0048C41.8507 24.2064 40.7379 25.9344 40.5659 27.8712C40.3075 30.7744 42.2475 33.5632 45.5075 34.9768C45.5579 34.9984 45.6099 35.0088 45.6619 35.0136C45.8307 34.7928 46.0027 34.5696 46.1779 34.348C46.1331 34.2216 46.0387 34.1136 45.9059 34.056C43.0491 32.8176 41.3451 30.4248 41.5643 27.96C41.7083 26.3376 42.6611 24.876 44.2451 23.8464C45.8683 22.7912 47.9499 22.3168 50.1075 22.5088C52.2651 22.7008 54.2307 23.536 55.6419 24.8608C57.0195 26.1544 57.6987 27.7608 57.5547 29.3832C57.4019 31.0976 56.3395 32.6304 54.6195 33.6528C54.7611 33.9536 54.8795 34.2736 54.9715 34.6088C57.0683 33.4232 58.3683 31.5696 58.5547 29.472Z" fill="#B2DFF8"/>
    <path d="M46.2587 34.2448C46.2315 34.2792 46.2043 34.3127 46.1771 34.3471C46.0011 34.5687 45.8299 34.792 45.6611 35.0128C45.6147 35.0736 45.5683 35.1344 45.5219 35.1952C44.8819 36.0336 44.2771 36.8256 43.6555 37.3784C43.1115 37.848 42.5819 38.2696 42.0699 38.6776C40.2515 40.1248 38.6811 41.3752 37.5147 43.8888C36.8219 45.2736 36.0011 46.3088 35.0771 47.2728C34.8147 47.5464 34.5435 47.8152 34.2643 48.084C33.9907 48.3472 33.7099 48.612 33.4219 48.8824C32.7923 49.4744 32.1411 50.0864 31.4771 50.7816C30.2027 51.9984 29.8123 53.5696 29.4347 55.0896C29.1163 56.3728 28.8147 57.5855 28.0019 58.4943C27.8171 58.7007 27.8347 59.0176 28.0411 59.2024C28.2475 59.3872 28.5651 59.3696 28.7491 59.1632C29.7283 58.0696 30.0739 56.6776 30.4083 55.3312C30.7683 53.8832 31.1075 52.516 32.1771 51.5L32.1947 51.4824C32.8427 50.8032 33.4867 50.1984 34.1091 49.6128C35.7243 48.0952 37.2491 46.6616 38.4179 44.324C38.8443 43.4048 39.3371 42.6656 39.8827 42.0216C39.7851 41.984 39.6867 41.9463 39.5875 41.9135C39.3243 41.8263 39.1827 41.5416 39.2699 41.2792C39.3571 41.016 39.6419 40.8744 39.9043 40.9616C40.1491 41.0432 40.3835 41.1376 40.6131 41.2368C40.9579 40.8992 41.3195 40.5824 41.6955 40.2696C41.5915 40.1856 41.4931 40.1008 41.4059 40.0168C41.2059 39.8256 41.1987 39.508 41.3899 39.3072C41.5811 39.1064 41.8987 39.0999 42.0995 39.2911C42.2115 39.3983 42.3475 39.5088 42.4963 39.6208C42.5619 39.568 42.6275 39.516 42.6947 39.4632C43.2147 39.0496 43.7515 38.6216 44.3163 38.1336C44.9595 37.5616 45.5467 36.8144 46.1579 36.0152C46.1067 35.92 46.0627 35.8215 46.0291 35.7191C45.9155 35.3687 45.8755 34.8432 46.2587 34.2448Z" fill="white"/>
    <path d="M42.299 58.8855C42.503 58.8807 42.7078 58.8543 42.9118 58.8103C43.287 56.5599 43.7574 54.7079 44.3726 53.0711C44.5014 52.7279 44.6366 52.3935 44.779 52.0679C44.9166 51.7519 45.0614 51.4423 45.2126 51.1391C45.283 50.9983 45.3582 50.8439 45.439 50.6791C45.9566 49.6199 46.7382 48.0199 47.5342 47.4007C47.9406 47.0687 48.3454 46.8063 48.7366 46.5519C49.5014 46.0559 50.2918 45.5431 51.0766 44.5223C51.2862 44.2383 51.5054 43.9479 51.7294 43.6519C53.2054 41.6999 54.8782 39.4887 55.1838 37.0407L55.1854 37.0223C55.2582 36.1887 55.179 35.3631 54.971 34.6087C54.879 34.2735 54.7606 33.9543 54.619 33.6527C54.3702 33.1239 54.0502 32.6543 53.6646 32.2783C52.8294 31.4623 51.7382 31.1039 50.503 31.2407C48.8638 31.4303 47.6014 32.6175 46.5062 33.9407C46.6854 33.8063 46.9374 33.8023 47.1198 33.9487C47.3358 34.1215 47.371 34.4375 47.1982 34.6535C47.1502 34.7135 47.1134 34.7687 47.0814 34.8223C48.1198 33.5279 49.2326 32.3967 50.6158 32.2367C51.5526 32.1335 52.3414 32.3879 52.9638 32.9951C53.4926 33.5111 53.8758 34.2799 54.0678 35.1463C54.0974 35.1175 54.1254 35.0903 54.155 35.0607C54.3518 34.8655 54.6694 34.8671 54.8646 35.0639C55.0598 35.2607 55.0582 35.5783 54.8614 35.7735C54.6502 35.9831 54.4318 36.1935 54.207 36.3983C54.2078 36.5735 54.2014 36.7495 54.187 36.9255C53.911 39.1031 52.327 41.1983 50.9294 43.0471C50.703 43.3463 50.4806 43.6399 50.275 43.9191C49.6014 44.7951 48.9158 45.2391 48.1902 45.7103C47.7758 45.9791 47.3478 46.2567 46.9086 46.6167C45.939 47.3711 45.1286 49.0287 44.5374 50.2391C44.4582 50.4007 44.3846 50.5527 44.315 50.6903C43.1438 53.0327 42.3734 55.6887 41.8174 59.2975C41.859 59.0679 42.0574 58.8911 42.299 58.8855Z" fill="white"/>
    <path d="M41.7309 68.9832C41.6069 68.7352 41.7077 68.4344 41.9557 68.3104C42.0621 68.2568 42.1965 68.2104 42.3525 68.164C42.3565 68.1128 42.3621 68.0616 42.3653 68.0112C42.4445 67.0184 42.4685 65.988 42.4845 64.9776C42.2965 64.9952 42.1941 64.996 42.1797 64.996C41.9029 64.996 41.6781 64.7712 41.6781 64.4944C41.6781 64.2176 41.9029 63.9928 42.1797 63.9928C42.1829 63.9928 42.2925 63.9904 42.5013 63.9664C42.5253 62.5896 42.5693 61.1896 42.7517 59.8544C42.6093 59.8728 42.4669 59.8848 42.3237 59.888C42.3197 59.888 42.3157 59.888 42.3117 59.888C42.0405 59.888 41.8165 59.6712 41.8101 59.3984C41.8093 59.364 41.8117 59.3304 41.8181 59.2976C41.8173 59.3008 41.8173 59.304 41.8165 59.3072C41.5437 61.0136 41.5157 62.804 41.4893 64.5352C41.4717 65.664 41.4541 66.8304 41.3653 67.9376C41.0869 72.116 38.2581 74.956 35.7381 75.508C35.4677 75.5672 35.2957 75.8344 35.3557 76.1056C35.4061 76.34 35.6141 76.5 35.8445 76.5C35.8805 76.5 35.9157 76.496 35.9525 76.488C39.3541 75.744 41.5645 72.4192 42.1965 69.2584C42.1909 69.2584 42.1853 69.26 42.1797 69.26C41.9957 69.2608 41.8189 69.1592 41.7309 68.9832Z" fill="white"/>
    <path d="M54.8657 35.0647C54.6705 34.8679 54.3529 34.8671 54.1561 35.0615C54.1265 35.0911 54.0977 35.1183 54.0689 35.1471C52.4953 36.6927 51.2473 37.4943 49.6033 37.0527C48.4937 36.7479 47.1985 36.0687 46.9849 35.4103C46.9441 35.2855 46.9193 35.0959 47.0833 34.8231C47.1153 34.7695 47.1521 34.7143 47.2001 34.6543C47.3729 34.4383 47.3377 34.1223 47.1217 33.9495C46.9393 33.8039 46.6865 33.8079 46.5081 33.9415C46.4753 33.9663 46.4433 33.9943 46.4169 34.0279C46.3577 34.1015 46.3073 34.1743 46.2609 34.2455C45.8769 34.8431 45.9169 35.3695 46.0313 35.7199C46.0649 35.8223 46.1081 35.9207 46.1601 36.0159C46.8537 37.3031 49.0713 37.9463 49.3417 38.0207C49.7337 38.1263 50.1073 38.1743 50.4649 38.1743C51.9497 38.1743 53.1609 37.3511 54.2089 36.3991C54.4345 36.1943 54.6521 35.9839 54.8633 35.7743C55.0593 35.5791 55.0609 35.2615 54.8657 35.0647Z" fill="white"/>
    <path d="M41.3908 39.308C41.1996 39.508 41.2068 39.8264 41.4068 40.0176C41.4948 40.1016 41.5932 40.1856 41.6964 40.2704C42.5516 40.964 43.878 41.6496 44.4516 41.8992C44.5164 41.9272 44.5844 41.9408 44.6508 41.9408C44.8444 41.9408 45.0292 41.828 45.1116 41.6392C45.222 41.3848 45.1052 41.0896 44.8516 40.9792C44.3268 40.7512 43.2332 40.176 42.4964 39.6224C42.3476 39.5104 42.2116 39.3992 42.0996 39.2928C41.8996 39.1 41.582 39.1072 41.3908 39.308Z" fill="white"/>
    <path d="M39.2711 41.2792C39.1831 41.5416 39.3255 41.8264 39.5887 41.9136C39.6879 41.9464 39.7855 41.984 39.8839 42.0216C40.3047 42.1832 40.7143 42.38 41.1127 42.5744C41.5191 42.772 41.9391 42.976 42.3583 43.1336C42.4167 43.1552 42.4759 43.1656 42.5343 43.1656C42.7375 43.1656 42.9287 43.0416 43.0039 42.84C43.1015 42.5808 42.9695 42.2912 42.7103 42.1944C42.3351 42.0536 41.9543 41.8688 41.5511 41.672C41.2479 41.524 40.9367 41.3752 40.6143 41.2368C40.3847 41.1376 40.1503 41.0432 39.9055 40.9616C39.6431 40.8744 39.3591 41.0168 39.2711 41.2792Z" fill="white"/>
    <path d="M38.2526 56.6849C38.9974 55.6425 39.1438 54.3257 38.6534 53.0729C38.5526 52.8145 38.2614 52.6873 38.0038 52.7881C37.7454 52.8889 37.6182 53.1801 37.719 53.4377C38.0886 54.3833 37.9886 55.3289 37.4366 56.1009C36.8118 56.9753 35.7014 57.4873 34.5286 57.4361C34.2526 57.4201 34.0174 57.6385 34.0054 57.9153C33.9934 58.1921 34.2078 58.4265 34.4846 58.4385C34.5518 58.4417 34.619 58.4433 34.6854 58.4433C36.1166 58.4433 37.4694 57.7809 38.2526 56.6849Z" fill="white"/>
    <path d="M34.9759 36.7984C34.8767 36.54 34.5863 36.4104 34.3279 36.5104C34.2727 36.532 34.2183 36.5552 34.1631 36.5768C32.4999 37.232 30.9391 38.072 29.4287 38.8872C28.3279 39.4816 27.1895 40.0952 26.0039 40.6432C22.5415 42.0328 21.7687 44.4448 20.7895 47.4976L20.7039 47.7656C18.8895 54.0264 17.0511 63.4568 15.5743 71.0336C15.2343 72.7792 14.9127 74.428 14.6183 75.9008C14.5639 76.172 14.7399 76.4368 15.0119 76.4912C15.0439 76.4968 15.0775 76.5 15.1095 76.5C15.3439 76.5 15.5535 76.3352 15.6007 76.0968C15.8959 74.6216 16.2175 72.972 16.5575 71.2248C18.0303 63.6672 19.8639 54.2616 21.6623 48.0576L21.7439 47.8032C22.6783 44.8888 23.3535 42.7832 26.3879 41.5696L26.4119 41.5592C27.6295 40.9976 28.7855 40.3736 29.9039 39.7704C31.2607 39.0384 32.5551 38.3416 33.9303 37.756C34.1799 37.6496 34.4311 37.5455 34.6871 37.4471C34.9463 37.3471 35.0751 37.0568 34.9759 36.7984Z" fill="white"/>
    <path d="M50.0401 52.5232C49.8001 52.384 49.4937 52.4664 49.3545 52.7056C49.3521 52.7096 49.2729 52.8456 49.1305 53.0704C48.8529 53.5112 48.3305 54.2992 47.6457 55.1552C46.4425 56.6584 44.6953 58.428 42.9121 58.8104C42.7081 58.8544 42.5033 58.8808 42.2993 58.8856C42.0569 58.8912 41.8593 59.068 41.8177 59.2976C41.8177 59.2984 41.8177 59.2984 41.8177 59.2992C41.8121 59.332 41.8089 59.3656 41.8097 59.4C41.8161 59.6728 42.0393 59.8896 42.3113 59.8896C42.3153 59.8896 42.3193 59.8896 42.3233 59.8896C42.4665 59.8864 42.6097 59.8744 42.7513 59.856C44.5873 59.6168 46.3257 58.1672 47.6569 56.6928C47.6249 56.236 47.5825 55.8376 47.5409 55.5408C47.5017 55.2664 47.6929 55.0128 47.9665 54.9736C48.2409 54.9336 48.4945 55.1248 48.5337 55.3992C48.5353 55.4136 48.5465 55.4888 48.5617 55.6152C49.5689 54.332 50.1953 53.256 50.2225 53.2088C50.2481 53.1648 50.2633 53.1184 50.2745 53.0712C50.3241 52.8616 50.2353 52.636 50.0401 52.5232Z" fill="white"/>
    <path d="M47.9665 54.9721C47.6921 55.0113 47.5017 55.2649 47.5409 55.5393C47.5833 55.8361 47.6257 56.2345 47.6569 56.6913C47.8089 58.8769 47.7097 62.4361 46.1049 63.0201C44.2281 63.7025 43.0321 63.9057 42.5009 63.9665C42.2921 63.9905 42.1825 63.9929 42.1793 63.9929C41.9025 63.9929 41.6777 64.2177 41.6777 64.4945C41.6777 64.7713 41.9025 64.9961 42.1793 64.9961C42.1937 64.9961 42.2961 64.9953 42.4841 64.9777C42.9729 64.9313 44.0537 64.7681 45.7393 64.2081C45.7769 63.7873 45.7745 63.5073 45.7745 63.4993C45.7705 63.2225 45.9913 62.9945 46.2681 62.9897C46.2705 62.9897 46.2737 62.9897 46.2761 62.9897C46.5497 62.9897 46.7729 63.2089 46.7777 63.4833C46.7777 63.5049 46.7793 63.6241 46.7713 63.8129C47.9193 63.1889 48.5657 61.6185 48.6921 59.1289C48.7705 57.5865 48.6249 56.1473 48.5609 55.6137C48.5457 55.4873 48.5353 55.4121 48.5329 55.3977C48.4953 55.1241 48.2409 54.9321 47.9665 54.9721Z" fill="white"/>
    <path d="M46.7784 63.4841C46.7736 63.2097 46.5504 62.9905 46.2768 62.9905C46.2744 62.9905 46.2712 62.9905 46.2688 62.9905C45.992 62.9953 45.7704 63.2225 45.7752 63.5001C45.7752 63.5081 45.7784 63.7881 45.74 64.2089C45.6696 64.9953 45.456 66.2721 44.8152 67.1825C44.52 67.6017 43.5112 67.8609 42.8432 68.0329C42.66 68.0801 42.4968 68.1225 42.352 68.1649C42.196 68.2113 42.0616 68.2577 41.9552 68.3113C41.7072 68.4353 41.6072 68.7369 41.7304 68.9841C41.8184 69.1601 41.9952 69.2617 42.1792 69.2617C42.1848 69.2617 42.1904 69.2601 42.196 69.2601C42.2664 69.2577 42.3368 69.2425 42.4032 69.2089C42.5136 69.1537 42.8072 69.0785 43.092 69.0049C43.9504 68.7841 45.1264 68.4825 45.6344 67.7609C46.5688 66.4337 46.7408 64.5657 46.7712 63.8145C46.78 63.6249 46.7792 63.5049 46.7784 63.4841Z" fill="white"/>
    </Svg>
  )
}
