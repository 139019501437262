import React from 'react'
import styled from 'styled-components'
import variables from '../../styles/variables'

const Svg = styled.svg`
  
  background-color:#0000 ;
`
export function Safety() {
  return (
    
    <Svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M69.7449 16.7808L69.5929 16.2184L69.0105 16.1976C51.9121 15.5792 40.6545 5.1192 40.5433 5.0136L39.9993 4.5L39.4569 5.0144C39.3457 5.12 28.1153 15.5784 10.9889 16.1976L10.4065 16.2184L10.2545 16.7808C10.1505 17.1648 7.74254 26.3208 10.1289 38.3024C12.3305 49.3576 19.2329 64.9216 39.6153 76.2856L40.0001 76.5L40.3849 76.2856C60.7665 64.9216 67.6697 49.3576 69.8713 38.3024C72.2569 26.32 69.8489 17.164 69.7449 16.7808ZM68.3074 38.0624C66.1745 48.7 59.5281 63.6568 40.0001 74.6896C20.4721 63.6568 13.8257 48.7 11.6929 38.0624C9.68814 28.0672 11.1753 19.9064 11.6497 17.7496C27.0857 16.992 37.5377 8.7776 40.0001 6.648C42.4625 8.7776 52.9146 16.9912 68.3513 17.7504C68.825 19.9072 70.3121 28.068 68.3074 38.0624Z" fill="white"/>
<path d="M45.761 23.5992H34.8986V32.0376H25.6162V42.9H34.8986V51.3384H45.761V42.9H54.1994V32.0376H45.761V23.5992ZM52.6194 33.6176V41.3192H44.181V49.7576H36.4786V41.32H27.1962V33.6184H36.4786V25.1792H44.181V33.6176H52.6194Z" fill="#B2DFF8"/>
</Svg>
  )
}
